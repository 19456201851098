const MODULE_NAME = 'registration';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  GET_TOURNAMENT_DETAILS: generateKeyName('GET_TOURNAMENT_DETAILS'),
  GET_EVENT_FORM: generateKeyName('get-event-form'),
  GET_SPORT_EVENT_LIST: generateKeyName('sport-event-list'),
  GET_SPORT_LIST_FORM: generateKeyName('sport-event-list-form'),
  GET_FILTERS: generateKeyName('get-filters'),
  GET_REGISTRATION_FORM_JSON: generateKeyName('GET_REGISTRATION_FORM_JSON'),
  GET_CART_OVERVIEW: generateKeyName('cart-overview'),
  GET_CART_SUMMARY: generateKeyName('cart-summary'),
  GET_CART_ITEMS: generateKeyName('cart-items'),
  ADD_TO_CART: generateKeyName('add-to-cart'),
  GET_PAYMENT_STATUS: generateKeyName('payment-status'),
  GET_FILLED_DATA_FORM: generateKeyName('filled-data-form'),
};

export default QUERY_KEYS;
