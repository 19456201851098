import QUERY_KEYS from './queryKeys';
import { useQuery } from 'react-query';
import {
  getCartItems,
  getCartOverview,
  getCartSummary,
  getCheckPaymentStatus,
  getEventForm,
  getFilters,
  getPaymentStatus,
  getRegistrationFormJson,
  getSportEventList,
  getSportListForm,
  getTournamentDetails,
  getFilledDataform,
  getSportEventListTournament
} from 'registration/services';

export const useRegistrationFormJson = (params) => {
  // backend will decide if the user is an athlete/coach/institute...
  return useQuery(
    [...QUERY_KEYS.GET_REGISTRATION_FORM_JSON, params.tournament_id, params.invite_id],
    () => getRegistrationFormJson(params),
    {
      retry: false,
    }
  );
};

export const useTournamentDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_DETAILS, params.tournament_id],
    () => getTournamentDetails(params),
    {
      retry: false,
    }
  );
};

export const useSportListForm = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_LIST_FORM, params.tournament_id],
    () => getSportListForm(params),
    {
      retry: false,
    }
  );
};

export const useSportEventList = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENT_LIST,
      params.tournament_id,
      params.gender,
      params.age_category,
      params.sport,
      params.sport_category,
      params.weight_category,
    ],
    () => getSportEventList(params),
    {
      retry: false,
    }
  );
};
export const useSportEventListTournament = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENT_LIST,
      params.tournament_id,
      params.gender,
      params.age_category,
      params.sport,
      params.sport_category,
      params.weight_category,
    ],
    () => getSportEventListTournament(params),
    {
      retry: false,
    }
  );
};

export const useFilters = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_FILTERS, params.tournament_id, params.sport],
    () => getFilters(params),
    {
      retry: false,
    }
  );
};

export const useEventForm = ({ sportEvent, onSuccess, onError }) => {
  return useQuery([...QUERY_KEYS.GET_EVENT_FORM, sportEvent], () => getEventForm(sportEvent), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useFilledDataForm = ({ formId, onSuccess, onError }) => {
  return useQuery([...QUERY_KEYS.GET_EVENT_FORM, formId], () => getFilledDataform(formId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useCartOverview = () => {
  return useQuery([...QUERY_KEYS.GET_CART_OVERVIEW], () => getCartOverview(), {
    retry: false,
  });
};

export const useCartSummary = (params) => {
  return useQuery([...QUERY_KEYS.GET_CART_SUMMARY], () => getCartSummary(params), {
    retry: false,
  });
};
export const useCartItems = () => {
  return useQuery([...QUERY_KEYS.GET_CART_ITEMS], () => getCartItems(), {
    retry: false,
  });
};

export const usePaymentStatus = (params) => {
  return useQuery([...QUERY_KEYS.GET_PAYMENT_STATUS], () => getPaymentStatus(params), {
    retry: false,
    cacheTime: 0,
  });
};

export const useCheckInstitutePayment = (params) => {
  return useQuery([...QUERY_KEYS.GET_PAYMENT_STATUS], () => getCheckPaymentStatus(params), {
    retry: false,
  });
};
