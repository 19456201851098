import React from 'react';
import banner from '../../assets/img/uttarakhand_desktop.jpg';
import icon from '../../assets/img/TournamentLogo.jpg';
import { useTournamentDetails } from 'registration/queries/hooks.js';
import DetailItemLoader from 'components/DetailItemLoader';
import { getDateInMonthDate } from '../../constants/DateFunctions';
import { useParams } from 'react-router-dom';
// import { useUserProfile } from 'profiles/queries/profileHooks';

export default function MainNav({ navOnly, noBackButton = false }) {
  // const { data } = useUserProfile();
  // const userPrimaryRole = data.message.primary_role ?? null;

  const params = useParams();
  const tournamentDetailsQueryData = useTournamentDetails({
    tournament_id: params.tournament_id,
  });
  // const history = useHistory();

  return (
    <div className="">
      {navOnly ? null : (
        <DetailItemLoader queryData={tournamentDetailsQueryData}>
          {({ data }) => {
            return (
              <div>
                {/* <img
                  src={SFAPlayMobileLogo}
                  alt="Sfa Play Mobile Logo"
                  className="block md:hidden"
                  onClick={() => {
                    history.push('/');
                  }}
                /> */}
                <div
                  className="bg-center h-22 md:h-60 bg-cover w-full font-roboto flex flex-col justify-between"
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) -15.83%, rgba(0, 0, 0, 0) 158.12%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 125.58%), url('${banner}')`,
                  }}
                >
                  <div
                    className={`${
                      noBackButton ? 'opacity-0' : 'pl-4 md:pl-16 md:pt-8 py-7 md:pb-10 '
                    }`}
                  >
                    {/* <img
                      src={angleLeft}
                      alt="angleLeft"
                      className="hidden md:block cursor-pointer"
                      onClick={() => {
                        history.goBack();
                      }}
                    /> */}
                  </div>
                  <div className="hidden md:flex items-end justify-between px-4 md:px-16 pb-4">
                    <div className="flex items-end">
                      {/* <img src={MainNavIcon} alt="logo" className="w-30 h-30" /> */}
                      <div
                        style={{
                          backgroundImage: `url(${
                            data.tournament_image !== 'img_url' ? data?.tournament_image : icon
                          }`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                        }}
                        className={'rounded-md w-24 h-24'}
                      />
                      <div className="pl-4">
                        <p className="text-white  text-3.5xl font-bold">{data?.tournament_name}</p>
                        <p className="text-xs">&nbsp;</p>
                      </div>
                    </div>
                    {/* <div className={`${userPrimaryRole === 'coach' ? 'hidden' : ''}`}>
                      <p className="text-white text-3xl font-bold text-right">
                        ₹ {data?.tournament_fees}
                      </p>
                      <p className="text-white text-sm text-right">Per Registration</p>
                    </div> */}
                  </div>
                </div>
                {/* Mobile Version */}
                <div className="flex md:hidden items-center gap-3 px-5 py-4 bg-white">
                  <div
                    style={{
                      backgroundImage: `url(${
                        data.tournament_image !== 'img_url' ? data.tournament_image : icon
                      })`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                    className="w-12 h-12 mr-4 md:mr-0 rounded-lg"
                  />
                  <div>
                    <p className="text-gray-750 text-l leading-6 font-bold">
                      {data?.tournament_name}
                    </p>
                    <div className="text-gray-650 text-xxs flex gap-1">
                      <p>{data?.city}</p>
                      <p>|</p>
                      <p>{`${getDateInMonthDate(
                        data?.tournament_regi_start_date
                      )} - ${getDateInMonthDate(data?.tournament_regi_end_date)}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </DetailItemLoader>
      )}
    </div>
  );
}
