import Http from 'utils/Http';

const apiEndPoints = {
  getTournamentDetails: 'sfa_online.v1.registrations.tournament.get_tournament_details',
  getEventForm: 'sfa_online.v1.registrations.tournament.get_sport_event_form',
  submitEventForm: 'sfa_online.v1.registrations.tournament.submit_form',
  getFilters: 'sfa_online.v1.registrations.tournament.get_filters',
  getRegistrationFormJson: 'sfa_online.v1.registrations.tournament.get_tournament_form',
  getSportEventList: 'sfa_online.v1.registrations.tournament.get_sport_events',
  getSportEventListTournament: 'sfa_online.v1.registrations.tournament.get_sport_events_tournament',
  getSportListForm: 'sfa_online.v1.registrations.institution.get_sport_list_form',
  getCartOverview: 'sfa_online.v1.registrations.tournament.get_cart_overview',
  getCartSummary: 'sfa_online.v1.registrations.cart.get_cart_summary',
  applyCoupon: 'sfa_online.v1.registrations.cart.apply_coupon',
  removeCoupon: 'sfa_online.v1.registrations.cart.removed_coupon',
  getCartItems: 'sfa_online.v1.registrations.cart.get_cart_items',
  removeCartItem: 'sfa_online.v1.registrations.cart.remove_from_cart',
  submitSportList: 'sfa_online.v1.registrations.institution.submit_sport_list',
  addSportEventToCart: 'sfa_online.v1.registrations.cart.add_to_cart',
  setCart: 'sfa_online.v1.registrations.tournament.set_tournament_cart',
  paymentStatus: 'sfa_online.v1.registrations.cart.get_payment_status',
  checkInstitutePayment: 'sfa_online.v1.registrations.institution.check_institution_paid',
  getFillDataform: 'sfa_online.v1.registrations.tournament.get_filled_form_data',
  coachVerificationRequest: 'sfa_online.v1.registrations.tournament.create_verification_request',
  checkSchoolValidationBeforeSubmission: 'sfa_online.v1.registrations.tournament.school_validation',
  getPrefilledFormData: 'sfa_online.v1.registrations.tournament.get_filled_form_data',
};

export async function getRegistrationFormJson(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getRegistrationFormJson}?tournament_id=${params.tournament_id}&invite_id=${params.invite_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getTournamentDetails(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTournamentDetails}?tournament_id=${params.tournament_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getFilters(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getFilters}?tournament_id=${params.tournament_id}&sport=${params.sport}&age_category=${params.age_category}&sport_category=${params.sport_category}&weight_category=${params.weight_category}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportListForm(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getSportListForm + `?tournament_id=${params.tournament_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint:
        apiEndPoints.getSportEventList +
        `?tournament_id=${params.tournament_id}&gender=${params.gender}&age_category=${params.age_category}&sport=${params.sport}&sport_category=${params.sport_category}&weight_category=${params.weight_category}`,
    });

    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventListTournament(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint:
        apiEndPoints.getSportEventListTournament +
        `?tournament_id=${params.tournament_id}&gender=${params.gender}&age_category=${params.age_category}&sport=${params.sport}&sport_category=${params.sport_category}&weight_category=${params.weight_category}`,
    });

    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getCartOverview(_params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getCartOverview,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getCartItems() {
  try {
    const res = await Http.fetch({
      apiEndpoint: apiEndPoints.getCartItems,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getCartSummary() {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getCartSummary}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}
export async function checkSchoolValidationBeforeSubmission(inviteId, schoolName, tournamentId) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.checkSchoolValidationBeforeSubmission}`,
      method: 'POST',
      options: {
        invite_id: inviteId,
        school: schoolName,
        tournament_id: tournamentId,
      },
    });
    if (res.message.error) throw new Error(res.message.error);

    return { res };
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function submitTournamentForm({ formTemplateName, formSubmission, data, ...params }) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.submitEventForm}`,
      method: 'POST',
      options: {
        tournament_id: params.tournament_id,
        form_template_name: formTemplateName,
        form_submission: formSubmission,
      },
    });
    return { res, data, formSubmission };
  } catch (error) {
    console.log(error);
  }
}

export async function submitTournamentFormCoach({
  formTemplateName,
  formSubmission,
  data,
  tournamentId,
}) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.submitEventForm}`,
      method: 'POST',
      options: {
        form_template_name: formTemplateName,
        form_submission: formSubmission,
        tournament_id: tournamentId,
      },
    });
    return { res, data, formSubmission };
  } catch (error) {
    console.log(error);
  }
}

export async function getEventForm(sportEvent) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getEventForm}`,
      method: 'POST',
      options: {
        sport_event: sportEvent,
      },
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getFilledDataform(formId) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getFillDataform}`,
      method: 'POST',
      options: {
        form_id: formId,
      },
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function uploadCouponData(value) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.applyCoupon}`,
      method: 'POST',
      options: value,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function removeAppliedCoupon(value) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.removeCoupon}`,
      method: 'POST',
      options: value,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function uploadSupportFormData({ department, description, photo, contact }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.uploadSupportFormData}`,
      method: 'POST',
      options: {
        department,
        description,
        photo,
        contact,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function removeCartItem(value) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.removeCartItem}`,
      method: 'POST',
      options: value,
    });
  } catch (error) {
    throw error.message;
  }
}

export const submitSportList = (value) => {
  try {
    return Http.fetch({
      apiEndpoint: `${apiEndPoints.submitSportList}`,
      method: 'POST',
      options: value,
    });
  } catch (error) {
    console.log(error);
  }
};

export async function addSportEventToCart({ sportEvent }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.addSportEventToCart}`,
      method: 'POST',
      options: {
        sport_event: sportEvent,
      },
    });
  } catch (error) {
    throw error.message;
  }
}

export async function postTournamentDetails(params) {
  // for cart generation
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTournamentDetails}`,
      method: 'POST',
      options: params,
    });
  } catch (error) {
    throw error.message;
  }
}

export async function setCart({ tournamentId, inviteId, referralCode }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.setCart}`,
      method: 'POST',
      options: {
        tournament_id: tournamentId,
        invite_id: inviteId,
        referral_code: referralCode,
      },
    });
  } catch (error) {
    throw error.message;
  }
}

export async function getPaymentStatus({ tournamentId }) {
  try {
    // let response = 'default';
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const response = await Http.fetch({
          apiEndpoint: `${apiEndPoints.paymentStatus}?tournament=${tournamentId}`,
          method: 'GET',
        });
        return resolve(response);
      }, 9000);
    }).then((response) => {
      return response;
    });

    // return response;
  } catch (error) {
    throw error.message;
  }
}

export async function getCheckPaymentStatus({ tournamentId }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.checkInstitutePayment}?tournament=${tournamentId}`,
      method: 'GET',
    });
  } catch (error) {
    throw error.message;
  }
}

export async function coachVerificationRequest({ tournamentId, sportEvents }) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.coachVerificationRequest}`,
      method: 'POST',
      options: {
        tournament_id: tournamentId,
        sport_events: sportEvents,
      },
    });
  } catch (error) {
    throw error.message;
  }
}

export async function getPrefilledFormData(formId, inviteId, institution) {
  try {
    return await Http.fetch({
      apiEndpoint: `${apiEndPoints.getPrefilledFormData}`,
      method: 'POST',
      options: {
        form_id: formId,
        invite_id: inviteId,
        institution,
      },
    });
  } catch (error) {
    throw error.message;
  }
}
